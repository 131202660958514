import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/login')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/register')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/verify')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/new-password')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/reset-password')));

export const LeadCreatePage = Loadable(lazy(() => import('../pages/dashboard/lead/new')));
export const LeadEditPage = Loadable(lazy(() => import('../pages/dashboard/lead/[id]/edit')));
export const LeadProfilePage = Loadable(lazy(() => import('../pages/dashboard/lead/[id]/profile')));
export const LeadListPage = Loadable(lazy(() => import('../pages/dashboard/lead/list')));
export const CustomersPage = Loadable(lazy(() => import('../pages/dashboard/lead/customers')));

export const OrderListPage = Loadable(lazy(() => import('../pages/dashboard/orders/list')));
export const OrderEditPage = Loadable(lazy(() => import('../pages/dashboard/orders/[id]/edit')));
export const OrderCreatePage = Loadable(lazy(() => import('../pages/dashboard/orders/new')));
export const OrderLeadCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/orders/[id]/new'))
);
export const OrderExpiredAllPage = Loadable(
  lazy(() => import('../pages/dashboard/orders/expired/all'))
);
export const OrderExpiredPage = Loadable(
  lazy(() => import('../pages/dashboard/orders/expired/index'))
);
export const OrderViewPage = Loadable(lazy(() => import('../pages/dashboard/orders/[id]/index')));
export const CompanySetting = Loadable(lazy(() => import('../pages/CompanySetting')));
export const BankingPage = Loadable(lazy(() => import('../pages/dashboard/banking')));

export const CalendarPage = Loadable(lazy(() => import('../pages/dashboard/calendar')));
export const InvitePage = Loadable(lazy(() => import('../pages/InvitePage')));
export const Payment = Loadable(lazy(() => import('../pages/payment')));
export const AccountExpensePage = Loadable(
  lazy(() => import('../pages/dashboard/accounts/expense'))
);
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/user/account')));
export const EcommerceProductCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/products/new'))
);
export const EcommerceProductListPage = Loadable(
  lazy(() => import('../pages/dashboard/products/list'))
);
export const AccountListPage = Loadable(lazy(() => import('../pages/dashboard/accounts/list')));
export const AccountNewPage = Loadable(lazy(() => import('../pages/dashboard/accounts/new')));
export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));
export const FileManagerDrivePage = Loadable(
  lazy(() => import('../pages/dashboard/FileManagerDrivePage'))
);

export const SalesPage = Loadable(lazy(() => import('../pages/dashboard/app')));
export const PerformancePage = Loadable(lazy(() => import('../pages/dashboard/dashboard')));
// export const AccountNewPage = Loadable(lazy(() => import('../pages/dashboard/accounts/')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const ContactPage = Loadable(lazy(() => import('../pages/ContactPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const InvoiceDownload = Loadable(lazy(() => import('../pages/dashboard/invoiceDownload')));
