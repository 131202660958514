// import { HOST_WEB_KEY } from '../config';
import { HOST_API_KEY } from '../config-global';
import axios from '../utils/axios';


export const getAvatar = (id) => {
  const url = `${HOST_API_KEY}/api/user/profile/avatar/${id}`;
  // const {data} = await axios.get(url)
  return url;
};

export const updateUser = async (data) => {
  if (data?.avatar) {
    console.log(data?.avatar);
    const bodyFormData = new FormData();
    bodyFormData.append('avatar', data?.avatar);
    await axios.post('/api/user/profile/avatar', bodyFormData);
  }
  if (!data?.mobile) {
    delete data?.mobile;
  }
  if (!data?.email) {
    delete data?.email;
  }

  delete data?.avatar;
  const response = await axios.post('/api/user/profile/update', data);

  return response;
};
export const getUserList = async () => {
  const response = await axios.get('/api/user/list');
  return response;
};

export const inviteUser = async (data) => {
  const response = await axios.post('/api/agent/create', data);
  return response;
};
export const inviteLogin = async (id, data) => {
  const response = await axios.post(`/api/agent/register/${id}`, data);
  return response;
};

export const getAgent = async (id) => {
  const response = await axios.get(`/api/agent/invite/${id}`);
  return response;
};
export const UsersList = async () => {
  const response = await axios.get('/api/agent/list');
  return response;
};

export const forgetPassword = async (data) => {
  const response = await axios.post('/api/user/password/reset', data);
  return response;
};

export const changePassword = async (token, data) => {
  const response = await axios.post(`/api/user/password/reset/${token}`, data);
  return response;
};

export const updateAgent = async (id, data) => {
  const response = await axios.post(`/api/agent/update/${id}`, data);
  return response;
};
