import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Typography, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { getLeadAvatar, getLeadQueryList } from '../../../services/LeadSer';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [leads, setLeads] = useState([]);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const fetchLeads = async () => {
    try {
      const { data } = await getLeadQueryList('Upcoming Follow Up');

      setLeads(data?.leads);
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    fetchLeads();
  }, []);
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon="eva:people-fill" width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Leads <Typography component="span">({leads?.length})</Typography>
        </Typography>

        <Scrollbar sx={{ maxHeight: ITEM_HEIGHT * 6 }}>
          {leads
            ?.map((contact) => (
              <ListItemButton
                key={contact._id}
                onClick={() => navigate(PATH_DASHBOARD.lead.profile(contact?._id))}
              >
                {/* <MenuItem key={contact.id}> */}
                <ListItemAvatar sx={{ position: 'relative' }}>
                  <CustomAvatar
                    name={contact?.name}
                    src={contact?.avatar && getLeadAvatar(contact?._id)}
                  />

                  {/* <BadgeStatus status={contact.status} sx={{ position: 'absolute', right: 1, bottom: 1 }} /> */}
                </ListItemAvatar>

                <ListItemText
                  primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                  primary={contact?.name}
                  secondary={fDate(contact?.followUpDate)}
                />
                {/* </MenuItem> */}
              </ListItemButton>
            ))
            .reverse()}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
