import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
// ----------------------------------------------------------------------
const Logo = forwardRef(({ disabledLink = false, sx }) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
    <svg width="100%" height="100%" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
          <stop offset="0%" stopColor={PRIMARY_DARK} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor={PRIMARY_LIGHT} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor={PRIMARY_LIGHT} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
      </defs>
      
      <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M384.487 256.686C414.49 319.072 444.118 381.459 473.748 443.846C482.373 461.738 490.624 480.087 500 499.813C478.622 501.188 460.995 494.766 443.369 487.885C393.488 469.077 355.61 429.626 321.855 382.836C305.729 364.487 288.102 348.431 272.35 329.623C267.475 325.494 262.975 319.989 258.099 315.403C255.098 311.733 251.723 309.439 247.597 308.063C243.097 306.228 239.721 301.64 235.221 299.347C229.595 295.218 222.47 295.218 217.219 289.255C216.469 287.879 216.845 286.502 217.594 285.126C253.598 274.576 279.475 249.345 291.102 204.848C292.602 199.344 294.103 193.839 292.978 188.333C306.104 186.04 316.981 195.215 327.482 202.096C349.609 215.858 368.36 234.207 384.487 256.686Z"
          fill="url(#BG1)"
        />
        <path
          d="M384.486 256.684C377.361 254.391 372.485 247.969 367.609 242.464C350.733 223.656 332.731 206.225 310.227 197.967C304.228 195.674 300.852 188.333 294.101 188.333C241.596 170.902 190.591 175.031 141.835 206.225C138.836 200.262 142.21 197.508 143.71 194.756C172.589 133.286 201.841 72.2753 230.345 10.8056C235.22 -0.203926 240.47 -2.49751 249.471 2.54851C260.723 8.97079 270.849 17.6865 277.225 30.0722C314.729 103.928 350.358 179.618 384.486 256.684Z"
          fill="url(#BG2)"
        />
        <path
          d="M51.2956 362.027L0 486.093C8.80834 498.296 93.7829 499.313 175.649 402.196C241.141 324.502 295.166 350.841 313.991 373.722C305.183 338.638 225.908 285.418 209.846 284.74C96.6845 284.74 66.3216 326.943 51.2956 362.027Z"
          fill="url(#BG3)"
        />
      </g>
      
    </svg>
  </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }
  return <Link href="/">{logo}</Link>;
});
Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};
export default Logo;