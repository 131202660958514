/* eslint-disable import/no-extraneous-dependencies */
import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';



export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}
export function fCalExpiryDate(date) {
  const firstDate = new Date();
  const secondDate = new Date(date);

  // To calculate the time difference of two dates
  const Difference_In_Time = secondDate.getTime() - firstDate.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const diffDays = Math.round(Difference_In_Days);
  if (diffDays <= 0) {
    return 'Expired';
  }
  return `${diffDays} day left`;
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}
export function fTimestampUnix(timestamp) {
  return format(new Date(timestamp * 1000), 'dd MMMM yyyy  p');
}
export function fTimestampUnixTime(timestamp) {
  return format(new Date(timestamp * 1000), 'p');
}
export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}
export function fTimestampDate(timestamp) {
  return format(new Date(timestamp * 1000), 'dd MMM yyyy');
}
export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: false,
  });
}
export function fToNowNew(date) {
  return date ? moment(date).fromNow() : '';
}
