// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  orders: getIcon('ic_orders'),
  settings: getIcon('ic_settings'),
  files: getIcon('ic_files'),
};

const navConfig = [
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.dashboard.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'sales', path: PATH_DASHBOARD.dashboard.sales },
          { title: 'performance', path: PATH_DASHBOARD.dashboard.performance },
        ],
      },
      { title: 'banking', path: PATH_DASHBOARD.banking, icon: ICONS.banking },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'file Manager',
        path: PATH_DASHBOARD.fileManager.root,
        icon: ICONS.files,
        children: [
          { title: 'AdsEra', path: PATH_DASHBOARD.fileManager.adsera },
          { title: 'Drive', path: PATH_DASHBOARD.fileManager.drive },
        ],
      },
    ],
  },
  // GENERAL
  {
    subheader: 'Lead Management',
    items: [
      // USER
      {
        title: 'Leads',
        path: PATH_DASHBOARD.lead.root,
        icon: ICONS.user,
        children: [
          { title: 'Leads', path: PATH_DASHBOARD.lead.list },
          { title: 'create', path: PATH_DASHBOARD.lead.new },
          { title: 'customers', path: PATH_DASHBOARD.lead.customers },
        ],
      },
      {
        title: 'Accounts',
        path: PATH_DASHBOARD.accounts.root,
        icon: ICONS.user,
        children: [
          { title: 'Account', path: PATH_DASHBOARD.accounts.list },
          { title: 'Expense', path: PATH_DASHBOARD.accounts.expense },
        ],
      },
      {
        title: 'ecommerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
        ],
      },

      {
        title: 'orders',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.orders,
        children: [
          { title: 'list', path: PATH_DASHBOARD.orders.list },
          { title: 'create', path: PATH_DASHBOARD.orders.new },
          { title: 'expire', path: PATH_DASHBOARD.orders.expired },
          { title: 'expire All', path: PATH_DASHBOARD.orders.expiredAll },
        ],
      },
    ],
  },

  {
    subheader: 'Company',
    items: [{ title: 'Settings', path: PATH_DASHBOARD.Companysetting, icon: ICONS.settings }],
  },
];

export default navConfig;
