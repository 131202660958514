/* eslint-disable no-nested-ternary */
import axios from '../utils/axios';

export const getLeadAvatar = (leadId) => {
  const url = `${process.env.HOST_API_KEY}/api/lead/profile/avatar/${leadId}`;

  return url;
};
export const createLead = async (data) => {
  const avatar = data?.avatar;

  delete data?.avatar;
  const { data: resData } = await axios.post('/api/lead/create', data);
  const lead = resData?.lead;
  if (avatar && lead) {
    const bodyFormData = new FormData();
    bodyFormData.append('avatar', avatar);
    await axios.post(`/api/lead/avatar/${lead._id}`, bodyFormData);
  }
  return resData;
};
export const updateStatus = async (id, data) => {
  const response = await axios.post(`/api/lead/update/status/${id}`, data);
  return response;
};
export const getLeadList = async ({ page, perPage }) => {
  const response = await axios.get(`/api/lead/list?page=${page}&perPage=${perPage}`);
  return response;
};
export const getLeads = async () => {
  const response = await axios.get('/api/lead/list/all');
  return response;
};
export const getFilteredLeads = async ({
  page,
  perPage,
  search = '',
  leadSource = '',
  leadTag = '',
  leadOwner = null,
  isCustomer = false,
  filterStatus = '',
}) => {
  const response = await axios.get(
    `/api/lead/search?page=${page}&perPage=${perPage}&search=${search}&leadSource=${leadSource}&leadTag=${leadTag}&leadOwner=${leadOwner}&isCustomer=${isCustomer}&leadStatus=${filterStatus}`
  );
  return response;
};

export const getLeadQueryList = async (query) => {
  const response = await axios.get(`/api/lead/list/query?status=${query}`);
  return response;
};

export const getCustomers = async ({ page, perPage }) => {
  const response = await axios.get(`/api/lead/customer/list?page=${page}&perPage=${perPage}`);
  return response;
};
export const updateLead = async (id, data) => {
  const avatar = data?.avatar;

  delete data?.avatar;
  const { data: resData } = await axios.post(`/api/lead/update/${id}`, data);
  const lead = resData?.lead;

  // type === Buffer => upload avatar
  // type === String => not upload
  console.log(avatar && typeof avatar !== 'string', lead);
  if (avatar && typeof avatar !== 'string') {
    const bodyFormData = new FormData();
    bodyFormData.append('avatar', avatar);
    await axios.post(`/api/lead/avatar/${lead._id}`, bodyFormData);
  }
  return resData;
};

export const getLead = async (id) => {
  const response = await axios.get(`/api/lead/view/${id}`);
  return response;
};

export const getSelectLead = async () => {
  const response = await axios.get('/api/lead/list/select');
  return response;
};
